<template>
  <div class="shortCodeGeneratorDashboard">
    <vue-scroll :ops="ops">
      <div class="shortCodeGeneratorDashboard__header">
        <div class="shortCodeGeneratorDashboard__title">Carousel</div>
      </div>
      <div class="shortCodeGenerator__items" v-for="(item, index) in items" :key="item.key">
        <div class="shortCodeGenerator__subheader">{{index + 1}}</div>
        <div class="shortCodeGenerator__row">
          <div class="shortCodeGenerator__row__title">Photo link</div>
          <textarea v-model="item.photoLink" />
        </div>
        <div class="shortCodeGenerator__row">
          <div class="shortCodeGenerator__row__title">Caption</div>
          <textarea v-model="item.caption" />
        </div>
      </div>
      <div class="shortCodeGeneratorDashboard__button" @click="addItemsRow">
        <span class="text">Add photo</span>
      </div>
      <div class="shortCodeGenerator__row">
        <div class="shortCodeGeneratorDashboard__button bottom" @click="generateShortCode">
          <span class="text">Generate carousel shortcode</span>
        </div>
      </div>
      <div class="shortCodeGenerator__row result">
        <textarea v-model="shortcode" />
      </div>
      <div>
        <div class="shortCodeGeneratorDashboard__button" @click="copy(shortcode)">
          <span class="text">Copy</span>
        </div>
        <div class="shortCodeGeneratorDashboard__button" @click="clear">
          <span class="text">Clear</span>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
import moment from 'moment'
import './ShortcodeGenerator.styl'

export default {
    name: 'shortcode-generator',
    data: () => ({
        shortcode: '',
        items: [{
          caption: '',
          photoLink: '',
        }],
        ops: {
          rail: {
            size: '3px',
          },
          bar: {
            size: '3px',
            background: 'rgba(0,0,0,0.1)',
          },
        },
    }),
    methods: {
      moment,
      async generateShortCode () {
        let isError = false;
        this.shortcode = '<div>[image_carousel images="';
        this.items.forEach(item => {
          if (this.isValidUrl(item.photoLink)) {
            this.shortcode += `${item.photoLink} | ${item.caption.replaceAll('"', '\'')} || `;
          } else {
            this.shortcode = `${item.photoLink} - invalid url`;
            isError = true;
          }
        });

        if (isError) {
          return;
        }
        this.shortcode = this.shortcode.substring(0, this.shortcode.length - 4);
        this.shortcode += '"]</div>';
      },
      addItemsRow () {
        this.items = [...this.items, {
          caption: '',
          photoLink: '',
        }];
      },
      isValidUrl (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
      },
      copy (text) {
        navigator.clipboard.writeText(text);
      },
      clear () {
        this.items = [{
          caption: '',
          photoLink: '',
        }];
        this.shortcode = '';
      },
    },
}
</script>
